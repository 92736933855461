<template>
<div id="topnav"></div>
  <section class="login-area">
      <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
          <div class="login-image">
            <img src="assets/img/login-bg.jpg" alt="image" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
          <div class="login-content">
            <div class="d-table">
              <div class="d-table-cell">
                <div class="login-form">
                  <div class="logo">
                    <router-link :to="{ name: 'Home' }">
                      <img class="logo" src="@/assets/img/logo.png" alt="image"/>
                    </router-link>
                  </div>
                  <h3>Welcome back</h3>
                  <p>New to Raque? <router-link :to="{ name: 'TraineeRegister', query: { redirect: $route.query.redirect  } }">Sign up</router-link></p>
                  <form @submit.prevent="loginUser">
                    <div class="form-group">
                      <input type="email" v-model="form.email" placeholder="Your email address" class="form-control" />
                      <div v-if="errors.email" class="text-small text-danger text-start">{{ errors.email[0] }}</div>
                    </div>
                    <div class="form-group">
                      <input type="password" v-model="form.password" placeholder="Your password" class="form-control"/>
                      <div v-if="errors.password" class="text-small text-danger text-start">{{ errors.password[0] }}</div>
                    </div>
                    <div class="d-grid gap-2">
                      <button class="btn default-btn" disabled v-if="submitting">
                        {{ value }}
                      </button>
                      <button type="submit" class="btn default-btn" v-else>Login</button>
                    </div>
                    <div class="forgot-password">
                      <router-link :to="{ name: 'ForgetPassword' }">Forgot Password?</router-link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import axios from 'axios'
export default {
  components: {  },
  data(){
    return{
        alerts: false,
        values: [],
        passwordFieldType: "password",
        passwordIcon: true,
        submitting: false,
        form:{
            email: '',
            password: '',
            device_name: 'browser'
        },
        errors: {}
        
    }
  },
  methods:{
    loginUser(){
      this.submitting = true
      this.value = 'Please Wait...'
      axios.post('https://apitraining.vipawaworks.com/api/auth/user/login',this.form).then(response =>{
      localStorage.setItem('user_data', response.data.token)
      this.alerts = true
        this.$router.push(this.$route.query.redirect || { name: 'TrainerDashboard'})
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.submitting = false
      })
    },
    switchVisibility() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
        this.passwordIcon = this.passwordIcon === true ? false : true
    }
  }
}
</script>

<style>

</style>